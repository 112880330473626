<!--
 * @Description:车牌输入键盘组件
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-04-08 18:46:01
 * @LastEditors: zhoucheng
-->
<template>
  <div class=''>
    <van-popup v-model="value"
               :overlay="false"
               position="bottom"
               round
               :style="{ height: '245px',background:'#E2E3E7',overflow: 'hidden' }">
      <div class="pop-top">
        <div @click="handleClickEmpty"
             class="poptopleft">清空</div>
        <div @click="close"
             class="poptopRight">X</div>
      </div>
      <div class="plat-box">
        <div class="row">
          <div v-for="item in keyObj[keyType].row1"
               :key="item"
               class="col">
            <div class="content"
                 @click="butClick(item)">{{item}}</div>
          </div>
        </div>
        <div class="row">
          <div v-for="item in keyObj[keyType].row2"
               :key="item"
               class="col">
            <div class="content"
                 :style="item==='I'||item==='O'? 'background:#D4D4D4;color: #818181;':'' "
                 @click="butClick(item)">{{item}}</div>
          </div>
        </div>
        <div class="row">
          <div class="placeholder">
          </div>
          <div v-for="item in keyObj[keyType].row3"
               :key="item"
               class="col">
            <div class="content"
                 @click="butClick(item)">{{item}}</div>
          </div>
          <div class="placeholder">
          </div>
        </div>
        <div class="row">
          <div class="col switcType">
            <div class="content"
                 @click="switcType">{{typeText[keyType]}}</div>
          </div>
          <div class="placeholder3">
          </div>
          <div v-for="item in keyObj[keyType].row4"
               :key="item"
               class="col">
            <div class="content"
                 @click="butClick(item)">{{item}}</div>
          </div>
          <div class="col del">
            <div class="content"
                 @click="clackDel">删 除</div>
          </div>
          <div class="placeholder2">
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    // 这里存放数据
    return {
      // 键盘类型 0车牌键盘， 1大写字母+数字
      keyType: 1,
      typeText: {
        0: 'ABC',
        1: '省份'
      },
      keyObj: {
        0: {
          row1: ['渝', '川', '鄂', '陕', '甘', '湘', '豫', '桂', '鲁'],
          row2: ['贵', '京', '宁', '蒙', '冀', '晋', '苏', '浙', '皖'],
          row3: ['赣', '云', '津', '沪', '黑', '吉', '辽', '粤'],
          row4: ['闽', '青', '藏', '新', '琼']
        },
        1: {
          row1: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
          row2: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
          row3: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
          row4: ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
        }
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    butClick (data) {
      // 设置I和O无法输入
      if (data === 'I' || data === 'O') {
      } else {
        this.$emit('keyClick', data)
      }
    },
    clackDel () {
      this.$emit('clackDel')
    },
    switcType () {
      if (this.keyType === 0) {
        this.keyType = 1
      } else {
        this.keyType = 0
      }
      this.$emit('typeChange', this.keyType)
    },
    close () {
      console.log('1459b703ceae578588ca8a8c299c1b43')
      this.$emit('close')
      this.$emit('change', this.val)
    },
    handleClickEmpty () {
      this.$emit('handleClickEmpty')
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.pop-top {
  width: 100%;
  height: 30px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  padding-left: 10px;
  .poptopleft {
    font-size: 15px;
  }
  .poptopRight {
    font-size: 15px;
    margin-right: 20px;
  }
}
.plat-box {
  margin: 10px 2px;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.col {
  width: 10%;
  height: 45px;
  padding: 2px;
}
.placeholder {
  width: 3%;
}
.placeholder2 {
  width: 17%;
}
.placeholder3 {
  width: 14%;
}
.del {
  position: absolute;
  right: 0;
  width: 13%;
  .content {
    background: #2fa4e5;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #ffffff;
  }
}
.switcType {
  position: absolute;
  width: 12%;
  left: 0;
}
.content {
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 15px;
  font-weight: 800;
  background: #ffffff;
  border-radius: 5px;
  &:active {
    background: rgb(241, 241, 241);
  }
}
/deep/.van-popup__close-icon--top-right {
  top: 5px;
}
</style>
