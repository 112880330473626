<!--
 * @Description:巡检员端 登记 车牌输入框组件 register
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-04-08 18:03:22
 * @LastEditors: zhoucheng
-->
<template>
  <van-password-input ref="plate"
                      :value="value"
                      :mask="false"
                      :gutter="5"
                      :length="platLength"
                      :focused="showKeyboard" />
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    platName: {
      type: String,
      default: ''
    },
    platLength: {
      type: Number,
      default: 8
    }
  },
  model: {
    prop: 'platName',
    event: 'change'
  },
  data () {
    // 这里存放数据
    return {
      value: '',
      showKeyboard: false,
      plateList: ['京', '津', '晋', '冀', '蒙', '辽', '吉', '黑', '沪', '苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '桂', '琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁', '新']
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {
    platName (n, o) {
      if (n.length <= this.platLength) {
        if (this.verifyPlat(n)) {
          this.value = n
        } else {
          this.$emit('change', o)
        }
      } else {
        this.$emit('change', o)
      }
      const plateList = this.$refs.plate.children
      const idx = n.length
      for (let i = 0; i < plateList.length; i++) {
        const ele = plateList[i]
        if (![idx, plateList.length - 1].includes(i)) {
          ele.style.border = 'solid 1px #DBDBDB'
        }
      }
      const item = plateList[idx]
      const last = plateList[plateList.length - 1]
      item.style.border = 'solid 1px #2FA4E5'
      last.style.border = 'solid 1px #67D767'
    },
    value (n, o) {
      this.$emit('change', n)
    }

  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.value = this.platName
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.monitor()
    const plateList = this.$refs.plate.children
    const last = plateList[plateList.length - 1]
    last.style.border = 'solid 1px #0BBD05'
    last.style.position = 'relative'
    var node = document.createElement('DIV')
    var textnode = document.createTextNode('新能源')
    node.style.color = 'white'
    node.style.background = '#0BBD05'
    node.style.borderRadius = '25px'
    node.style.fontSize = '12px'
    node.style.position = 'absolute'
    node.style.width = '40px'
    node.style.padding = '2px'
    node.style.textAlign = 'center'
    node.style.top = '-12px'
    node.appendChild(textnode)
    last.appendChild(node)
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    monitor () {
      // 监听车牌省份点击事件
      const plateList = this.$refs.plate.children
      for (let i = 0; i < plateList.length; i++) {
        const item = plateList[i]
        item.addEventListener('click', res => {
          for (let idx = 0; idx < plateList.length; idx++) {
            const ele = plateList[idx]
            if (idx !== i) {
              ele.style.border = 'solid 1px #DBDBDB'
            }
          }
          item.style.border = 'solid 1px #2FA4E5'
          const last = plateList[plateList.length - 1]
          last.style.border = 'solid 1px #67D767'
          this.$emit('clickPlat', i)
        })
      }
    },
    // 校验车牌
    verifyPlat (data) {
      if (data === '') {
        return true
      } else {
        data = data.split('')
        if (data.length === 1) {
          if (this.plateList.indexOf(data[0]) !== -1) {
            return true
          }
        } else {
          if (this.plateList.indexOf(data[data.length - 1]) === -1) {
            return true
          }
        }
      }
      return false
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.van-password-input {
  margin: 0;
}
.van-password-input__item {
  height: 46px;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  font-family: PingFangSC-Medium;
  font-size: 15px;
  color: #333333;
  letter-spacing: 0;
}
.van-password-input__security > li:first-child {
  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 10px;
    height: 10px;
    background: #ffffff;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    right: 2px;
    width: 16px;
    height: 8px;
    transform: rotatez(-45deg);
  }
  &:active {
  }
}
</style>
